import * as React from 'react';

import { AppProps, SeoObject } from 'src/app/types';
import { ButtonTw, Localized } from 'src/app/components';
import { useStyles } from 'src/app/context';

import { useLocalizedNavigation } from 'src/app/hooks';
import {
  AppPageLayout,
  AppPageLinks,
  organisationHelpTopics,
  HelpTopicContainer,
  HelpTopicSearch,
} from 'src/app.consumer/components';

const seo: SeoObject = {
  title: 'Supplier help page',
};

// TODO: i18n
export const SupplierHelpPage = (props: AppProps) => {
  const { navigateLocalized } = useLocalizedNavigation();
  const onContactButtonClick = () => {
    navigateLocalized('/contact'); // TODO: eval create /contact/partner route with extra org func call params
  };
  return (
    <AppPageLayout
      sidebar={<AppPageLinks />}
      subTitle={<Localized dictKey={'staticLocales:pages./help.pages./supplier.subtitle'} />}
      title={
        <Localized dictKey={'staticLocales:pages./help.pages./supplier.link label'} />
      }
      {...{ ...props, seo }}
    >
      <div>
        <HelpTopicSearch topics={organisationHelpTopics} />
        <div className='pt-8 pb-2'>
          <Localized dictKey={'staticLocales:pages./help.pages./supplier.browse-topics.title'} />
        </div>
        {organisationHelpTopics?.map((topic, idx) => {
          return (
            <div key={topic.id} >
              <HelpTopicContainer
                key={idx}
                dictKey={'shop:/help/organisation.topic'}
                topicData={topic}
              />
            </div>
          );
        }) || null}
        <div className='text-center'>
          <ButtonTw
            variant={'accent'}
            rounded
            size = 'lg'
            onClick={onContactButtonClick}
            className='mt-4 w-full md:w-auto md:min-w-[270px]'
          >
            <Localized dictKey={'staticLocales:pages./help.pages./supplier.button.label'} />
          </ButtonTw>
        </div>
      </div>
    </AppPageLayout>
  );
};
export default SupplierHelpPage;
